h1.index_pageTitle_3CFMZ {
  font: normal normal 500 24px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 21px;
}
h2.index_cardTitle_290kK, .card > .card-header > h2 {
  padding: 10px 20px;
  font: normal normal 500 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  border-left: 4px solid #5068C2;
}
.ant-btn {
  font: normal normal 500 14px/30px "Noto Sans JP", sans-serif;
}
.ant-btn.index_filledBtn_12uIV {
    background: #5068C2 0% 0% no-repeat padding-box !important;
    border-style: none !important;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal 500 14px/30px "Noto Sans JP", sans-serif !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    padding: 0 18px;
}
.ant-btn.index_filledBtn_12uIV span {
      color: #FFFFFF !important;
}
.ant-btn.index_filledBtn_12uIV:hover {
      opacity: 0.2;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_12uIV) {
    color: #5068C2 !important;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8DA;
    border-radius: 4px;
    opacity: 1;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_12uIV):hover {
      background: #EFEFF7 0% 0% no-repeat padding-box;
      border: 1px solid #5068C2 !important;
      border-radius: 4px;
      opacity: 1;
}
.ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f2f4f8 !important;
    box-shadow: none !important;
    pointer-events: none;
}
.ant-popover-buttons button {
  line-height: 24px !important;
}
.ant-pagination-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8DA;
  border-radius: 4px;
}
.ant-pagination-item:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #5068C2;
    border-radius: 4px;
}
.ant-pagination-item-active {
  background: #EFEFF7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #5068C2;
}
.ant-list-pagination {
  text-align: center;
}
.ant-pagination {
  text-align: center !important;
}
.ant-list-item:hover {
  background: #EFEFF7 0% 0% no-repeat padding-box;
}
.ant-list-item .ant-list-item-action-split {
  width: 0;
}
.card > .card-header {
  padding-left: 0 !important;
}
.card .card-body {
  padding: 16px !important;
}
@media screen and (max-width: 767px) {
.card .card-body {
      padding-left: 12px !important;
}
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner {
  border-color: #BBBBBB !important;
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner::after {
    border-color: #BBBBBB !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner {
  border-color: #5068C2 !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner::after {
    background-color: #5068C2 !important;
    border-color: #5068C2 !important;
}
.ant-tabs-nav {
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  color: #3042A0;
  font: normal normal 500 14px/20px "Noto Sans JP", sans-serif;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3042A0;
    background: #EFEFF7 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
}
.ant-tabs-nav .ant-tabs-tab-active {
    background: #3042A0 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    color: #FFF;
}
.ant-tabs-nav .ant-tabs-tab-active:hover {
      background: #3042A0 0% 0% no-repeat padding-box;
      color: #FFF;
}
.ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #3042A0 !important;
}
.ant-checkbox-group .ant-checkbox-wrapper {
  font: normal normal normal 14px/33px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
    border: 1px solid #BBBBBB;
    border-radius: 2px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5068C2;
    border-color: #5068C2;
}
.ant-form .ant-form-item > .ant-form-item-label > label {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
span.index_requiredMark_CftTk {
  display: inline-block;
  width: 36px;
  height: 20px;
  background: #EF5350 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
  font: normal normal normal 11px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  margin: 8px;
  padding: 1px 6px;
}
.ant-table-wrapper table {
  table-layout: fixed;
  word-break: break-all;
  border-collapse: separate;
  border-radius: 4px;
  border: none !important;
}
.ant-table-wrapper table th {
    border: none !important;
}
.ant-table-wrapper tr {
  font: normal normal 300 14px/14px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-table-wrapper tr.index_stripe0_2HEhv {
    background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe0_2HEhv td.ant-table-column-sort {
      background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_-YP80 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_-YP80 td.ant-table-column-sort {
      background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr td {
    border: none;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter {
  vertical-align: top;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter .on {
    color: #5068C2 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-small .ant-table-body {
    margin: 0;
}
.ant-table-small .ant-table-body table {
      border-collapse: separate;
      border-radius: 4px;
}
.ant-table-small .ant-table-body table th {
        font: normal normal 500 12px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
        background: #EFF0F3 0% 0% no-repeat padding-box !important;
        height: 36px !important;
        padding: 0px 10px !important;
        border: none !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table th {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table td {
        font: normal normal 300 14px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        padding: 0px 10px !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table td {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table tr.index_stripe_fm1Mg {
        background: #FAFBFC 0% 0% no-repeat padding-box;
}
.index_targetDescription_rDwxj {
  display: flex;
  flex-wrap: wrap;
}
.index_targetDescription_rDwxj .index_targetItem_NNBBv {
    display: inline-block;
    margin-right: 32px;
}
.index_targetDescription_rDwxj .index_targetItem_NNBBv .index_targetLabel_1NJ_a {
      font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
      letter-spacing: 0px;
      color: #787B87;
}
.index_targetDescription_rDwxj .index_targetItem_NNBBv .index_targetLabel_1NJ_a::after {
        content: ':';
        margin-right: 10px;
}
.index_targetDescription_rDwxj .index_targetItem_NNBBv .index_targetValue_AoJ85 {
      font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
      letter-spacing: 0px;
      color: #1F2437;
}
.index_targetDescription_rDwxj .index_targetItem_NNBBv .index_dateBtnContainer_3DQUM {
      margin-right: 16px;
}
@media screen and (max-width: 767px) {
.index_targetDescription_rDwxj .index_targetItem_NNBBv .index_dateBtnContainer_3DQUM {
          margin-right: 10px;
}
}
.index_targetDescription_rDwxj .index_targetItem_NNBBv .index_dateBtnContainer_3DQUM > button {
        margin: 0 2px;
}
.index_targetDescription_rDwxj .index_targetItem_NNBBv br {
      display: none;
}
@media screen and (max-width: 767px) {
.index_targetDescription_rDwxj .index_targetItem_NNBBv br {
          display: inline;
}
}
@media screen and (max-width: 767px) {
.index_targetDescription_rDwxj .index_targetItem_NNBBv .index_datePicker_Fu_72 {
        width: 115px;
        height: 32px;
}
}
.index_cardHeader_2s1Ty {
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
}
.index_cardHeader_2s1Ty > * {
    display: inline-block;
}
@media screen and (max-width: 767px) {
.index_cardHeader_2s1Ty > * {
        display: block;
}
}
.index_cardHeader_2s1Ty .index_endPoints_3jj5l {
    font-size: 1.3rem;
}
.index_cardHeader_2s1Ty .index_buttons_1gGlF button {
    padding: 0 20px;
    height: 36px;
    margin-left: 12px;
}
@media screen and (max-width: 767px) {
.index_cardHeader_2s1Ty .index_buttons_1gGlF button {
        padding: 0 12px;
        margin-left: 8px;
}
}
@media screen and (max-width: 767px) {
.index_cardHeader_2s1Ty .index_buttons_1gGlF {
      margin-top: 12px;
}
}
@media screen and (max-width: 767px) {
.index_cardHeader_2s1Ty {
      display: block;
}
}
.index_scheduleItem_3n702 {
  margin-top: 10px;
  padding: 16px;
  background: #FAFBFC 0% 0% no-repeat padding-box;
  border: 1px solid #E6E6E7;
  border-radius: 4px;
  justify-content: space-between;
}
.index_scheduleItem_3n702 > .index_scheduleNameContainer_2Xd0c {
    display: inline-block;
    width: 300px;
    border-right: 1px solid #E6E6E7;
}
@media screen and (max-width: 767px) {
.index_scheduleItem_3n702 > .index_scheduleNameContainer_2Xd0c {
        width: 100%;
        border: none;
}
}
.index_scheduleItem_3n702 > .index_scheduleNameContainer_2Xd0c .index_icon_3gF4S {
      color: #5068C2;
      background-color: #FFFFFF;
      border: 1px solid;
}
.index_scheduleItem_3n702 > .index_scheduleNameContainer_2Xd0c .index_name_2GD8D {
      margin: 0 12px;
      font: normal normal bold 16px/20px "Noto Sans JP", sans-serif;
      letter-spacing: 0px;
      color: #1F2437;
}
.index_scheduleItem_3n702 > .index_scheduleInfoContainer_3NonO {
    display: inline-block;
    margin-left: 32px;
}
@media screen and (max-width: 767px) {
.index_scheduleItem_3n702 > .index_scheduleInfoContainer_3NonO {
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
}
}
.index_scheduleItem_3n702 > .index_scheduleInfoContainer_3NonO .index_scheduleInfoItem_2WDEo {
      margin-bottom: 3px;
}
.index_scheduleItem_3n702 > .index_scheduleInfoContainer_3NonO .index_scheduleInfoItem_2WDEo:last-child {
        margin-bottom: 0;
}
.index_scheduleItem_3n702 > .index_scheduleInfoContainer_3NonO .index_scheduleInfoItem_2WDEo .index_infoLabel_miMS1 {
        display: inline-block;
        width: 80px;
        font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #787B87;
        position: relative;
}
.index_scheduleItem_3n702 > .index_scheduleInfoContainer_3NonO .index_scheduleInfoItem_2WDEo .index_infoLabel_miMS1::after {
          content: ':';
          position: absolute;
          left: 60px;
}
.index_scheduleItem_3n702 > .index_scheduleInfoContainer_3NonO .index_scheduleInfoItem_2WDEo .index_infoValue_7TkrQ {
        font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
}
.index_scheduleItem_3n702 .index_editBtnContainer_1jouZ {
    display: inline-block;
    margin-left: auto;
}
.index_scheduleItem_3n702 .index_editBtnContainer_1jouZ > button {
      width: 80px;
      height: 36px;
}
.index_scheduleItem_3n702 > .index_scheduleItemName_3YGJt {
    font: normal normal 300 18px/33px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #1F2437;
}
.index_scheduleItem_3n702 > .index_scheduleItemMemo_3cRHK {
    font: normal normal 300 12px/20px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #707070;
}
